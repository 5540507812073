import React, {useEffect, useState} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useParams
} from "react-router-dom";
import Container from './component/Container'
import {RecoilRoot, useRecoilState} from "recoil";
import Project from "./component/page/Project";
import {info as myInfo} from "./store/my";
import NotFound from "./component/page/NotFound";


const habadu = () => {
    window.location = 'https://youtube.com/watch?v=j_MNxdEf4T4'
}
const puinit = () => {
    window.location = 'https://drive.google.com/drive/folders/1kCFrpl2DO0NiaNyIAggSCbn_P3caIN-X'
}



ReactDOM.render(
    <RecoilRoot>
        <Router>
            <Switch>
                <Route path="/habadu" component={habadu}/>
                <Route path="/puinit" component={puinit}/>
                <Route path={["/:key","/"]} >
                    <Container/>
                </Route>
            </Switch>
        </Router>
    </RecoilRoot>,
    document.getElementById('root')
);