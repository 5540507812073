import {atom} from 'recoil';

export const project = atom({
    key: 'project',
    default: {
        'kickey': {
            'name': 'Kickey',
            'position': 'Co-founder',
            'description': 'Platform for indie game publishing and copyright game rental on Steam/Origin/Ubisoft.',
            'logo': 'https://kickey.net/static/images/logo.png',
            'link': {
                'text': 'View website',
                'url': 'https://kickey.net'
            },
            'date': 'December 2020',
            'service': 'Gaming platform',
            'type': 'Software',
            'overview': {
                'description': 'Use right on the website without downloading. Simple operation, and constantly updating to meet user needs.',
                'image': [
                    'https://cdn.bandeck.com/9a33be861d1b793a7d54cb02f93ee723?cache=1',
                ]
            },
            'result': {
                'description': 'Statistics:',
                'list': [
                    'Topping the search results related to the keyword game rental on Google.',
                    'Over 300 games on Steam, Origin and Ubisoft platforms.',
                    'More than 60,000 people register for an account.'
                ]
            }
        },
        'bandeck': {
            'name': 'Bandeck',
            'position': 'Founder',
            'description': 'The content delivery network and proxy service for Google Drive.',
            'logo': '../static/image/bandeck.png',
            'link': {
                'text': 'View website',
                'url': 'https://bandeck.com'
            },
            'date': 'October 2021',
            'service': 'Cloud storage',
            'type': 'Software'
        },
        'memfy': {
            'key': 'memfy',
            'name': 'Memfy',
            'position': 'Author',
            'description': 'Lightweight real-time memory management application to monitor and clean system memory on your computer.',
            'logo': '../static/image/memfy.png',
            'link': {
                'text': 'Download now',
                'url': 'https://duyanh.me/static/file/MemfySetup.exe'
            },
            'date': 'August 2021',
            'service': 'Computer optimize',
            'type': 'Software',
            'overview': {
                'description': 'The program used undocumented internal system features (Native API) to clear system cache (system working set, working set, standby page lists, modified page lists) with variable result ~10-50%. Application it is compatible with Windows 7 or higher.',
                'image': [
                    'https://duyanh.me/static/image/memfy_thub.jpg',
                ]
            },
        }
    },
});
