import {useRecoilState} from "recoil";
import React, {useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch, useParams} from "react-router-dom";
import Home from './page/Home'
import Portfolio from './page/Portfolio'
import Project from './page/Project'
import NotFound from "./page/NotFound";
import ScrollToTop from "./ScrollToTop";
import {info as myInfo} from "../store/my";

export default function Container() {

    let params = useParams();
    const [useMyInfo, setMyInfo] = useRecoilState(myInfo);

    const [useKey, setKey] = useState(null)

    useEffect(() => {
        if (params) {
            let socialNetwork = Object.keys(useMyInfo.socialNetwork).reduce((c, k) => (c[k.toLowerCase().trim()] = useMyInfo.socialNetwork[k], c), {});
            if ('key' in params && (params.key).toLowerCase() in socialNetwork) {
                window.location = socialNetwork[(params.key).toLowerCase()];
                return;
            }
        }
        setKey('123');
    }, [params])

    return (useKey && (
            <div className="page-wrapper">
                <div data-collapse="medium" data-animation="default" data-duration="400"
                     data-w-id="58db7844-5919-d71b-dd74-2323ed8dffe9" data-easing="ease" data-easing2="ease"
                     role="banner"
                     className="header w-nav" style={{display: 'none'}}>
                    <div className="container-header">
                        <div className="split-content header-left">
                            <nav role="navigation" className="nav-menu w-nav-menu">
                                <ul role="list" className="header-navigation">
                                    <li className="nav-item-wrapper"><a
                                        href="portfolioztemplate_webflow_default.html"
                                        aria-current="page"
                                        className="nav-link w--current">Home</a>
                                    </li>
                                    <li className="nav-item-wrapper"><a href="about.htm"
                                                                        className="nav-link">Portfolio</a>
                                    </li>
                                    <li className="nav-item-wrapper"><a href="about.htm"
                                                                        className="nav-link">Contact</a>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                        <div className="split-content header-right">
                            <div className="menu-button w-nav-button">
                                <div className="menu-button-wrapper">
                                    <div className="menu-button-icon">
                                        <div className="menu-line-top"></div>
                                        <div className="menu-line-middle"></div>
                                        <div className="menu-line-bottom"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-default w-container">
                        <div className="divider"></div>
                    </div>
                </div>

                <ScrollToTop/>

                <Switch>
                    <Route path="/portfolio">
                        <Portfolio/>
                    </Route>
                    <Route path="/project/:key">
                        <Project/>
                    </Route>
                    <Route exact path="/">
                        <Home/>
                    </Route>
                    <Route path="/">
                        <NotFound/>
                    </Route>
                </Switch>

            </div>
        )
    );
}